import React from "react";


class ExtensionNotDetected extends React.Component {

    render() {
        return (<div>
            Wika Extension is not installed (go install it)
        </div>) ;
    }

}

export default ExtensionNotDetected;
