import React from "react";


class AccountNotDetected extends React.Component {

    render() {
        return (<div>
            No account is selected
        </div>) ;
    }

}

export default AccountNotDetected;
